import * as THREE from 'three';
import fragment from './shader/fragment.glsl';
import vertex from './shader/vertex.glsl';

const canvasElement = document.getElementById('canvas');
const title = document.querySelector('.title');
let colorSlider = document.querySelector('#palette');

let palette = [];
var colors = require('nice-color-palettes');
// let colorIndex = Math.floor(Math.random() * colors.length);
let colorIndex = 50;

const randomPalette = () => {
  colorIndex = Math.floor(Math.random() * colors.length);
  colorSlider.value = colorIndex;
  generateColorScheme();
};

const resetScene = () => {
  if (!window.ThreeInstance) return;
  ThreeInstance.material = null;
  ThreeInstance.geometry = null;
  ThreeInstance.plane = null;
  ThreeInstance.scene = new THREE.Scene();
  ThreeInstance.addObjects();
};

const generateColorScheme = () => {
  colorIndex = colorSlider.value;
  console.log(colorIndex);
  palette = colors[colorIndex];
  palette = palette.map((color) => new THREE.Color(color));

  const rndIndex = Math.round(Math.random() * 4);
  title.style.color = palette[rndIndex].getStyle();
  // canvasElement.style.backgroundColor = palette[rndIndex].getStyle();
  document.body.style.accentColor = palette[rndIndex].getStyle();
  colorSlider.value = colorIndex;

  resetScene();

  if (window.ThreeInstance) {
    ThreeInstance.renderer.setClearColor(palette[rndIndex].getStyle(), 1);
  }
};

generateColorScheme();
colorSlider.addEventListener('change', generateColorScheme);
title.addEventListener('click', randomPalette);

document.addEventListener('keyup', (e) => {
  colorSlider.focus();

  if (e.key === ' ') {
    randomPalette();
  }
});

export default class Sketch {
  constructor(options) {
    this.scene = new THREE.Scene();

    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    // this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      30,
      this.width / this.height,
      0.001,
      1000
    );

    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.set(0, 0, 0.2);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;

    // this.dracoLoader = new DRACOLoader();
    // this.dracoLoader.setDecoderPath(
    //   'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/'
    // ); // use a full url path
    // this.gltf = new GLTFLoader();
    // this.gltf.setDRACOLoader(this.dracoLoader);

    this.isPlaying = true;

    this.addObjects();
    this.resize();
    this.render();
    this.setupResize();
    // this.settings();
  }

  // settings() {
  //   let that = this;
  //   this.settings = {
  //     progress: 0,
  //   };
  //   this.gui = new GUI();
  //   this.gui.add(this.settings, 'progress', 0, 1, 0.01);
  // }

  setupResize() {
    window.addEventListener('resize', this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let that = this;
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: '#extension GL_OES_standard_derivatives : enable',
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        uColor: { value: palette },
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.geometry = new THREE.PlaneGeometry(1.5, 1.5, 300, 300);

    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
  }

  addLights() {
    const light1 = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 0.5);
    light2.position.set(0.5, 0, 0.866); // ~60º
    this.scene.add(light2);
  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.render();
    }
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.0002;
    this.material.uniforms.time.value = this.time;
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
  }
}

window.ThreeInstance = new Sketch({
  dom: canvasElement,
});
